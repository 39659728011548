import { useState } from "react"

const AddDetails = ({ onAdd }) => {

    const [name, setText] = useState('')
    const [age, setAge] = useState('')
    const [color, setColor] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault();


        if (!name) {
            alert("Please Enter Name")
            return
        }

        if (!age || isNaN(age)) {
            alert("Enter Valid Number")
            return
        }

        onAdd({ name, age, color })

        setText('')
        setAge('')
        setColor(false)



    }

    return (
        <>
            <form onSubmit={onSubmit} className="AddForm" style={{ padding: '20px', margin: '10px', borderRadius: "10px", border: '2px #000 solid' }}>
                <label>Name</label><br />
                <input type='text' placeholder='Enter Name'
                    value={name} onChange={(e) => setText(e.target.value)}
                /><br /><br />
                <label>Age</label><br />
                <input type='text' placeholder='Enter Age'
                    value={age} onChange={(e) => setAge(e.target.value)}
                /><br /><br />
                <label>Color </label>
                <input type='checkbox'
                    value={color} onChange={(e) => setColor(e.currentTarget.checked)}
                /><br /><br />
                <input type='submit' value='Submit' />
            </form>
        </>

    )
}

export default AddDetails