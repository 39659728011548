import PropTypes from 'prop-types'

const Buttons = ({ color, text, padding, onClickFunc }) => {
    const paddingText='rem'
    return (
        
        <button type='button' onClick={onClickFunc} style={
            { backgroundColor: color, padding: `${padding}rem`, margin:padding+paddingText, borderRadius:padding+paddingText}
        }>{text}</button>
    )
}

Buttons.defaultProps={
    color:'steelblue',
    onClickFunc :()=>{
        console.log("Default Log")
    }
}

Buttons.propTypes={
    color:PropTypes.string,
    padding:PropTypes.number.isRequired,
    text:PropTypes.string,
    onClickFunc:PropTypes.func
}

export default Buttons