// const Header = (params) => {
//   return (
//     <h1>{params.title}</h1>
//   )
// }

import Buttons from "./Buttons"

const Header = ({title,onAdd,showAddBtn}) => {

    return (
        <header>
            <h1 style={{color:'red', backgroundColor:'black'}}>{title} : Inline CSS</h1>
            <h2 style={h2_css}>{title} : variable CSS</h2>
            <Buttons 
             text={showAddBtn ? 'Close' : 'Add'}  
             padding={1} 
             onClickFunc={onAdd}
             color={showAddBtn ? 'red' : 'green'}
            />
            <Buttons text={'BUTTON-2'} color={'grey'} padding={1}/>
            <Buttons text={'BUTTON-3'} color={'grey'} padding={1}/>
        </header>
      
    )
  }

Header.defaultProps={
    title: 'Default Title'
}

const h2_css={
    color:'yellow', backgroundColor:'grey'
}

//PropTypes will be used to validate data Types
//Import proptypes i.e. import PropTypes from 'prop-types'  [impt for short cut]
// Header.PropTypes={
    // title:PropTypes.string    -> To check if it should be string
    // title:PropTypes.string.isRequired     ->To make it required to be string
// }

export default Header