//import icons
import { FaTimes } from 'react-icons/fa'

const Task = ({list,onDelete,onDouble}) => {
  return (
    <div onDoubleClick={()=>onDouble(list.id)} className={`task ${list.color ? 'reminder' : ''}`}>
        <h3>{list.name}  { list.deleted==false ? <FaTimes style={{color:'red'}} onClick={()=>onDelete(list.id)}/> : 'Deleted' }</h3>
    </div>
  )
}

export default Task