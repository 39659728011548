
import Header from './components/Header'
import Tasks from './components/Tasks'
import { useState } from 'react'
import AddDetails from './components/AddDetails'

function App() {

  const [stateGlobaltasks,setGlobalstateTasks] =useState([
    {
        id:1,
        name:"Vikash",
        age:24,
        deleted:false,
        color:true
    },
    {
        id:2,
        name:"Avi",
        age:24,
        deleted:false,
        color:false
    },
    {
        id:3,
        name:"Doob",
        age:22,
        deleted:true,
        color:false
    }
])


//Delete Name/Task
const deleteTask= (idInput) =>{
  //Remove elements from list/state
  setGlobalstateTasks(stateGlobaltasks.filter(
    (task) => task.id !==idInput
  ))
  //change element deleted from true to false
  // setGlobalstateTasks(stateGlobaltasks.map(
  //   (task) => task.id === idInput ? {
  //     ...task ,deleted: true  
  //   }  : task
    
  // ))
}

//change colour on doubleClick
const changeColorOfBorder =(id)=>{
  setGlobalstateTasks(stateGlobaltasks.map(
    (task)=>task.id===id ? { ...task, color: !task.color } : task 
  ))
}

//Open Add Details button
const  [showAddTask,setShowAddTask] =useState(false)

//Save Form
const addDetails=(task) =>{
  console.log(task)
  const id=Math.floor(Math.random()*1000+1)
  const deleted=false
  const newTask={id,deleted,...task}
  setGlobalstateTasks([...stateGlobaltasks,newTask])
  console.log(stateGlobaltasks)
}

  return (
    <div className="App">
      <Header onAdd={() => setShowAddTask(!showAddTask)} showAddBtn={showAddTask}/>
      <div>
        <Tasks onDelete={deleteTask} onDouble={changeColorOfBorder} stateGlobaltasks={stateGlobaltasks}/>
        {showAddTask && <AddDetails onAdd={addDetails} />}
      </div>
    </div>
  );
}

//To create class we need to import react i.e. import React from 'react'
//Class call will require reloading
// class AppClass extends React.Component{
//   render(){
//     return <h1>Return From Class</h1>
//   }
// }

export default App;
