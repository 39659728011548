
//Use list as state
import { useState } from "react"

import Task from "./Task"

//state is one way data so no array_push will work
//Use array like this
const tasks=[
    {
        id:1,
        name:"Vikash",
        age:"24",
        male:true
    },
    {
        id:2,
        name:"Avi",
        age:"24",
        male:true
    },
    {
        id:3,
        name:"Doob",
        age:"22",
        male:false
    }
]


const Tasks = ({stateGlobaltasks,onDelete,onDouble}) => {

    // const [statetasks,setstateTasks] =useState([
    //     {
    //         id:1,
    //         name:"Vikash",
    //         age:"24",
    //         male:true
    //     },
    //     {
    //         id:2,
    //         name:"Avi",
    //         age:"24",
    //         male:true
    //     },
    //     {
    //         id:3,
    //         name:"Doob",
    //         age:"22",
    //         male:false
    //     }
    // ])

//To insert into state
// setstateTasks([...stateTasks,{}])

  return (

    <>
        {stateGlobaltasks.map((task)=>(
        
            
            <Task onDelete={onDelete} key={task.id} onDouble={onDouble} list={task}/>
        
        ))}
        {/* {statetasks.map((task)=>(<h2 key={task.id}>{task.name}</h2>))} */}
        {/* Access from App.js */}
        {/* {stateGlobaltasks.map((task)=>(<h1 key={task.id}>{task.name}</h1>))} */}
    </>
  )
}

export default Tasks